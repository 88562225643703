<template>
  <div
      v-show="$store.state.app.isMobile"
      class="user-top-nav"
  >
    <a
        :class="{'menuActive':routeName[0] === item.name}"
        v-for="(item, i) in items"
        :key="i"
        @click="goTab(item.name)">{{item.title}}</a>
  </div>
</template>

<script>
export default {
  name: 'TopNavbar',
  components: {},
  data() {
    return {
      items: [
        {title: 'EDIT PROFILE', name: 'profile'},
        {title: 'WALLETS', name: 'wallets'},
        {title: 'TRANSFERS', name: 'transfers'},
      ],
      routeName: this.$route.name.split("/"),
    }
  },
  methods: {
    goTab(name) {
      this.$router.push({name: name})
    },
  }
}
</script>