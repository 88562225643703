<template>
  <div class="transfers">
    <left-side-bar/>
    <!-- transfers section-->
    <section class="transfers-section">
      <top-navbar />
      <p>Your Transfers</p>
      <p>Check the status of your transfers. For help visit the <a>help center</a>.</p>
      <div class="no-transfers">
        <v-icon color="#000" size="100">mdi-arrow-expand</v-icon>
        <p>
          YOU HAVEN'T TRANSFERRED ANYTHING YET
        </p>
        <p>
          If you purchased a token on royal without a metamask wallet, you may want to transfer it to one. To sell your
          token on a third party marketplace, or access any digital extras that came with it, you'll need to transfer to
          a metamask.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import LeftSideBar from "./LeftSidebar";
import TopNavbar from "@/views/User/Settings/TopNavbar";

export default {
  name: 'Transfers',
  components: {
    TopNavbar,
    LeftSideBar
  },
  data() {
    return {}
  },
  methods: {}
}
</script>