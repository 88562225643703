<template>
  <v-card
      v-show="!$store.state.app.isMobile"
      height="897"
      width="208"
  >
    <v-navigation-drawer permanent>
      <v-list
          dense
          nav
      >
        <v-list-item
            v-for="item in items"
            :key="item.title"
            link
        >
          <v-list-item-content @click="goTab(item.name)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  name: 'RightSlideBar',
  components: {},
  data() {
    return {
      items: [
        {title: 'EDIT PROFILE', name: 'profile'},
        {title: 'WALLETS', name: 'wallets'},
        {title: 'TRANSFERS', name: 'transfers'},
      ],
      right: null,
    }
  },
  methods: {
    goTab(name) {
      this.$router.push({name: name})
    },
  }
}
</script>
<style scoped>
.v-sheet.v-card {
  margin-top: 20px;
  box-shadow: none;
}

</style>